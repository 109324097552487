<script>
    export let title = "";
    export let subtitle = "";
</script>

<style>
    section {
        margin-top: 10rem;
        flex-direction: column;
        text-align: center;
    }
</style>

<section>
    <h3>{title}</h3>
    <h5>{subtitle}</h5>
</section>