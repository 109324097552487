<script>
    import { onMount } from 'svelte';
    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";
    import SocialButtons from "../../UI/socialButtons/socialButtons.svelte";
    import { labels } from "content";

    let yOffset = 0;

    let phrase = "We Are DevSonic.";
    let typedChars = "";
    let index = 0;
    let typeWriter;

    const typeChar = () =>{
        if(index < phrase.length){
            typedChars += phrase[index];
            index += 1;
        }else{
            clearInterval(typeWriter);
        }
    }

    const typing = () => typeWriter = setInterval(typeChar,100);

    onMount(() =>{
        typing();
    });
</script>

<svelte:window bind:scrollY={yOffset}/>

<style lang="scss">
    @import "header";
</style>

<section class="bottom-clip">
    <ContentWrapper disableFlex>
        <div class="intro" style="transform: translate(0,{-yOffset * 2 / 4}px);">
            <h2>{labels.header.greeting}</h2>
            <h2>{typedChars}</h2>
            <p>{labels.header.intro}</p>
            <div>
                <SocialButtons/>
            </div>
            
        </div>
    </ContentWrapper>
</section>