<script>
    import { fly } from "svelte/transition";

    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";

    import newIntersectionObserver from "helpers/intersectionObserver";
    import { labels } from "content";

    let intersecting;

    const intersectionListener = newIntersectionObserver(() => intersecting = true, "-150px");

    // Svelte Transitions Params
    const trans1 = { y: 200, duration: 750, delay: 250 };
</script>

<style lang="scss">
    @import "backend";
</style>

<section use:intersectionListener>
    <ContentWrapper disableMarginTop smallMarginBottom>
        {#if intersecting}
            <article transition:fly="{trans1}">
                <h4>{labels.stack.backendTitle}</h4>

                <p>{labels.stack.backendText}</p>

                <div class="banner">
                    <img src="/assets/logos/laravel.svg" alt="laravel"/>
                    <img src="/assets/logos/java.svg"  alt="java"/>
                    <img src="assets/logos/mysql.svg" alt="mysql"/>
                    <img src="/assets/logos/springio.svg" alt="spring"/>
                </div>
            </article>

            <div class="bubbles" transition:fly="{trans1}">
                <div class="laravel">
                    <img src="/assets/logos/laravel.svg" alt="laravel"/>
                </div>

                <div class="java">
                    <img src="/assets/logos/java.svg" style="height:70px;" alt="java"/>
                </div>

                <div class="mysql">
                    <img src="assets/logos/mysql.svg" style="height:70px;" alt="mysql"/>
                </div>

                <div class="spring">
                    <img src="/assets/logos/springio.svg" style="height:70px;" alt="spring"/>
                </div>
            </div>
        {/if}
    </ContentWrapper>
</section>