<script>
    import Navbar from "components/layout/navbar/navbar.svelte";
    import Title from "components/layout/title/title.svelte";
    import Footer from "components/layout/footer/footer.svelte";
    import Header from "components/sections/header/header.svelte"
    import Webdev from "components/sections/webdev/webdev.svelte";
    import Branding from "components/sections/branding/branding.svelte";
    import Mobile from "components/sections/mobile/mobile.svelte";
    import Ecommerce from "components/sections/ecommerce/ecommerce.svelte";
    import Support from "components/sections/support/support.svelte";
    import Frontend from "components/sections/frontend/frontend.svelte"
    import Backend from "components/sections/backend/backend.svelte";
    import Banner from "components/sections/banner/banner.svelte";

    import { labels } from "content";

    let workAnchor, aboutAnchor;
</script>


<Navbar workAnchor="{workAnchor}" aboutAnchor="{aboutAnchor}"/>

<main>
    <!--Header-->
    <Header/>

    <!--Services-->
    <span bind:this={workAnchor}></span>
    <Title title="{labels.titles.work}" subtitle="{labels.titles.workSubtitle}"/>
    <Webdev/>
    <Mobile/>
    <Branding/>
    <Ecommerce/>
    <Support />

    <!--Tech Stack-->
    <span bind:this={aboutAnchor}></span>
    <Title title="{labels.titles.stack}" subtitle="{labels.titles.stackSubtitle}"/>
    <Frontend/>
    <Backend/>

    <Banner />
</main>

<Footer workAnchor="{workAnchor}" aboutAnchor="{aboutAnchor}"/>