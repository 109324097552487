<script>
    import { fly } from "svelte/transition";

    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";
    import MockupDashboard from "../../UI/mockupDashboard/mockupDashboard.svelte";
    import MockupWeb from "../../UI/mockupWeb/mockupWeb.svelte";

    import newIntersectionObserver from "helpers/intersectionObserver";
    import { labels } from "content";

    let intersecting;

    const intersectionListener = newIntersectionObserver(() => intersecting = true, "-150px");

    // Transitions
    const trasn1 = { y: 70, duration: 1000, delay: 250 };
    const trans2 = { y: 70, duration: 1000, delay: 350 };
</script>

<style lang="scss">
    @import "ecommerce";
</style>

<section use:intersectionListener>

    <ContentWrapper>
        {#if intersecting}
            <article>
                <div transition:fly="{trasn1}">
                    <div>DevSonic</div>
                    <h3>{labels.ecommerce.name}</h3>
                </div>

                <p transition:fly="{trans2}">{labels.ecommerce.caption2}</p>
                <p transition:fly="{trans2}">{labels.ecommerce.caption}<p>
            </article>

            <div class="wrapper">
                <MockupWeb intersecting/>
            </div>
        {/if}
    </ContentWrapper>


    <ContentWrapper disableFlex disableMarginTop smallMarginBottom>
        <div class="header">
            <h4>{labels.ecommerce.title}</h4>
            <h4>{labels.ecommerce.subtitle}</h4>
        </div>

        <MockupDashboard/>
    </ContentWrapper>

</section>