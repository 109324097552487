<style lang="scss">
    @import "integrations";
</style>

<div class="wrapper">

    <div class="integration">
        <div class="logos">
            <div class="logo">
                <img src="/assets/logos/shopify.svg" alt="shopify"/>
            </div>

            <div class="slack">
                <img src="/assets/logos/wordpress.svg" alt="shopify"/>
            </div>
        </div>

        <div class="meta">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="integration">
        <div class="logos">
            <div class="restEndpoint">
                <p>{"{ REST }"}</p>
            </div>

            <div class="logo">
                <img src="/assets/logos/shopify.svg" alt="shopify"/>
            </div>
        </div>

        <div class="meta">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

</div>