export default [
    {
        name: "Taxify",
        sign: "-",
        min: 20,
        max: 50
    },
    {
        name: "Deposit",
        sign: "+",
        min: 500,
        max: 1800
    },
    {
        name: "Superbalist",
        sign: "-",
        min: 40,
        max: 100
    },
    {
        name: "Takealot",
        sign: "-",
        min: 10,
        max: 100
    },
    {
        name: "Pick N Pay",
        sign: "+",
        min: 200,
        max: 800
    },
    {
        name: "Virgin Active",
        sign: "-",
        min: 50,
        max: 200
    },
    {
        name: "Mug N Bean",
        sign: "+",
        min: 500,
        max: 1800
    },
    {
        name: "Bank Charges",
        sign: "-",
        min: 100,
        max: 400
    },
    {
        name: "Netflix",
        sign: "+",
        min: 1000,
        max: 1400
    },
    {
        name: "Clearwater Mall",
        sign: "-",
        min: 20,
        max: 28
    },
    {
        name: "Uber",
        sign: "-",
        min: 20,
        max: 50
    },
    {
        name: "Mr D.",
        sign: "-",
        min: 40,
        max: 70
    },
    {
        name: "PayPal",
        sign: "+",
        min: 100,
        max: 400
    },
    {
        name: "Apple Music",
        sign: "-",
        min: 9,
        max: 9
    }
];