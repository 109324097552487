<script>
    export let disableMarginTop = false;
    export let smallMarginBottom = false;
    export let disableFlex = false;


    const display = `display: ${disableFlex ? "block" : "flex"};`;
    //Smaller bottom & top margin when the user changes devices.
    const topMargin = disableMarginTop ? "margin-top: 0;" : "margin-top: 8rem;";
    const bottomMargin = smallMarginBottom ? "margin-bottom: 0;" : "margin-bottom: 8rem;";

    const style = display + topMargin + bottomMargin;
</script>

<style lang="scss">
    .wrapper {
        position: relative;
        width: 100%;
        max-width: 1250px;
        min-height: 13rem;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @media screen and (min-width: 768px) {
            flex-direction: row;
            align-items: normal;
        }
    }
</style>

<div class="wrapper" style="{style}">
    <slot/>
</div>