<script>
    import { fly } from "svelte/transition";

    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";

    import newIntersectionObserver from "helpers/intersectionObserver";
    import { labels } from "content";

    let intersecting;

    const intersectionListener = newIntersectionObserver(() => intersecting = true, "-150px");

   
    // Transitions
    const t1 = { y: 70, duration: 750, delay: 250 };
    const t3 = { y: 70, duration: 1100, delay: 400 };
    const t4 = { y: 70, duration: 1500, delay: 550 };
    const t5 = { x: 400, duration: 1000, delay: 250 };
</script>

<style lang="scss">
    @import "branding";
</style>

<section use:intersectionListener>
    <ContentWrapper>
        {#if intersecting}
            <article>
                <div transition:fly="{t1}">
                    <h3>{labels.branding.name}</h3>
                </div>
                <p transition:fly="{t3}">{labels.branding.caption}</p>

                <p transition:fly="{t4}">{labels.branding.caption2}</p>
            </article>
            <div class="showcase">
                <img src="/assets/images/branding.jpg" alt="showcase" transition:fly="{t5}"/>
            </div>
        {/if}
    </ContentWrapper>
</section>