<script>
    import { fly } from "svelte/transition";

    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";

    import createIntersectionObserver from "helpers/intersectionObserver";
    import { labels } from "content";

    let intersecting;

    const intersectionListener = createIntersectionObserver(() => intersecting = true, "-150px");

    // Transitions
    const trans1 = { y: 70, duration: 750, delay: 250 };
</script>

<style lang="scss">
    @import "frontend";
</style>

<section use:intersectionListener>
    <ContentWrapper disableMarginTop smallMarginBottom>
        {#if intersecting}
            <div class="bubbles" transition:fly="{trans1}">
                <div class="react">
                    <img src="/assets/logos/reactjs.svg" alt="react"/>
                </div>
                <div class="svelte">
                    <img src="/assets/logos/svelte.png" alt="svelte"/>
                </div>
                <div class="sass">
                    <img src="/assets/logos/sass.svg" alt="sass"/>
                </div>
                <div class="wordpress">
                    <img src="assets/logos/wordpress.svg" alt="wordpress"/>
                </div>
            </div>

            <article transition:fly="{trans1}">
                <h4>{labels.stack.frontendTitle}</h4>
                <p>{labels.stack.frontendText}</p>
                <div class="banner">
                    <img src="/assets/logos/reactjs.svg" alt="react"/>
                    <img src="/assets/logos/sass.svg" alt="sass"/>
                    <img src="/assets/logos/svelte.png" alt="svelte"/>
                    <img src="assets/logos/wordpress.svg" alt="wordpress"/>
                </div>
            </article>
        {/if}
    </ContentWrapper>
</section>