export default {
    nav: {
        work: "Services",
        about: "Technologies",
        portfolio: "Portfolio"
    },
    header: {
        greeting: "Hello",
        title: "We are DevSonic",
        intro: "DevSonic is a digital agency focused on web design and complementary services, with a small but passionate team DevSonic will deliver an amazing online experience."
    },
    titles: {
        work: "Services We Provide",
        workSubtitle: "What We Can Do",
        stack: "Stack",
        stackSubtitle: "These are our weapons of choice."
    },
    webdev: {
        name: "Website Development.",
        caption: "We pride ourselves on building lightweight websites that feature a high level of finesse through subtle animations, keeping the user wanting to stay on the site longer, but without compromising on functionality and intuition.",
        caption2: " Our development team finds natural breakpoints within the content and prioritise functionality based on known statistics, whilst removing unnecessary assets as the site responds to a user’s device. This produces a better end result in less time, and our clients get the opportunity to see our progress along the way in the form of a beta link. We ensure our websites are built to work across 95% of mobile devices and platforms.",
        caption3: "This produces a better end result in less time, and our clients get the opportunity to see our progress along the way in the form of a beta link. We ensure our websites are built to work across 95% of mobile devices and platforms."
    },
    branding:{
        name: "Branding.",
        caption: "We work with businesses to create both new, and re-imagined brand identities, that perfectly reflect the company. We offer various branding packages depending on the needs of the client. Our immediately recognisable brand identities undergo a range of services, including brand proposition strategy, logo designs, typography and colour selection, strategy, tone of voice, imagery styling, textures and patterns, plus packaging and point of sale.",
        caption2: " We will encompass the essence and culture of your business through our experience and design skills, working closely with you to achieve a distinctive look that speaks volumes for your organisation. Branding is one of the most crucial aspects of any business, large or small, and we are experts in both the creative end goal and the strategic brand proposition piece behind it."
    },
    payments: {
        name: "Web Design",
        mockup: "Transactions",
        caption: "We strategically design our websites via a series of workshops, wire-framing and user experience (UX) sessions, to end with a site that reinforces credibility, communicates key brand messages and brings you a return on creativity.",
        caption2: "Our designs will make you stand out from the competition and provide your users with an engaging and refreshing experience. We ensure our code is as lightweight as possible whilst not holding back our creative team on the front end design (UI)."
    },
    ecommerce: {
        name: "E-Commerce Development",
        caption: "Shopify is a licensed platform that is user friendly and an effective way for a small to medium sized business to sell products online. The admin interface is clean and focussed and benefits from automatic updates and security patches managed by Shopify.",
        caption2: "Through innovative design, strategy and bespoke development, we have the in-house skills and experience to deliver a successful E-Commerce project for your business. Our team provides high performance bespoke E-commerce solutions from simple Shopify websites, to integrated marketplace Magento builds.",
        title: "Live Statistics",
        subtitle: "Gather data and convert users into customers.",
        onlineStore: "DevSonic",
        chart: "Customer retention",
        latest: "Latest"
    },
    support: {
        name: "24 Hour Support",
        caption: "More than just web development, we aim to develop long-lasting relationships with all our clients. We want to see our clients succeed, grow, and expand into new markets. Whether you need advice or assistance implementing a new strategy, we’ll be there to help.",
        website: "Website",
        docs: "Documentation"
    },
    stack: {
        frontendTitle: "Attractive UI's",
        frontendText: "DevSonic frontend development stack utilizes basic HTML, CSS and sprinkles of JavaScript and leverages modern workflows. Our team of developers works with component based frameworks which result in faster development and efficient web applications. Our development process allows us to offer modern services like Single Page Apps(SPAs) and Progressive Web Apps(PWAs).",
        backendTitle: "Scalable Backend Architectures",
        backendText: "Choosing the most efficient technology mix for a project is an art that is difficult to master. Finding the right tools does not only require a clear vision and good understanding of the scope of the project but also a deep understanding of the impact different technologies can have. Our know-how paired with our analytical and forward-thinking approach enable our team to develop backend architectures that are maintainable and easy to scale."
    },
    banner: {
        title: "Contact Us!",
        subtitle: "We're always available for exciting discussions and projects. Get in touch."
    },
    footer: {
        copyright: "Copyright © 2021.",
        name: "DevSonic.",
        contact: "Contact.",
        email: "Email Us.",
        connect: "Connect With Us."
    }
};