<script>
    import { fly } from "svelte/transition";

    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";
    import Mockup from "../../UI/mockupMobile/mockup.svelte"

    import newIntersectionObserver from "helpers/intersectionObserver";
    import { labels } from "content";

    let intersecting;

    const intersectionListener = newIntersectionObserver(() => intersecting = true, "-150px");

    // Transitions
    const trans1 = { y: 100, duration: 950, delay: 450 };
    const trans2 = { y: 100, duration: 1200, delay: 500 };
</script>

<style lang="scss">
    @import "mobile";
</style>

<section use:intersectionListener class:intersecting class="top-clip bottom-clip">
    <ContentWrapper>
        {#if intersecting}
            <div class="mockup">
                <Mockup/>
            </div>

            <article>
                <h3 transition:fly="{trans1}">{labels.payments.name}</h3>
                <p transition:fly="{trans2}">{labels.payments.caption}</p>
                <p transition:fly="{trans2}">{labels.payments.caption2}</p>
            </article>
        {/if}
    </ContentWrapper>
</section>