<script>
    import ComponentWrapper from "../componentWrapper/componentWrapper.svelte";
    import SocialButtons from "components/UI/socialButtons/socialButtons.svelte";

    import {labels} from "content";

</script>

<style lang="scss">
    @import "footer";
</style>

<footer class="top-clip">
    <ComponentWrapper>
        <article>
            <span>
                <img  class ="logo" src="/assets/images/devsonic-logo.png" alt="logo">
                <b>DevSonic</b>
            </span>
        </article>

        <article>
            <ul>
                <li>{labels.footer.name}</li>
                <li>{labels.footer.copyright}</li>
            </ul>
        </article>

        <article>
            <b>{labels.footer.contact}</b>
            <ul>
                <li>
                    <a href="mailto:devsonic2020@gmail.com" target="_top">{labels.footer.email}</a>
                </li>
                <li>
                    Call : +27 73 899 2160
                </li>
                <li>
                    Call : +27 63 114 8022
                </li>
            </ul>
        </article>

        <article>
            <b>{labels.footer.connect}</b>
            <SocialButtons/>
        </article>
    </ComponentWrapper>
</footer>