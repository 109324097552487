<script>
    import { fly } from "svelte/transition";
    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";
    import MockupCard from "../../UI/mockupCards/mockupCards.svelte";

    import createIntersectionObserver from "helpers/intersectionObserver";
    import { labels } from "content";

    let intersecting;

    const intersectionListener = createIntersectionObserver(() => intersecting = true, "-150px");

    // Transition
    const trans1 = { y: 70, duration: 750, delay: 250 };
    const trans2 = { y: 70, duration: 1000, delay: 350 };
    const trans3 = { y: 70, duration: 1100, delay: 400 };
</script>

<style lang="scss">
    @import "support";
</style>

<section use:intersectionListener>
    <ContentWrapper smallMarginBottom>
        {#if intersecting}
            <article>
                <div transition:fly="{trans1}">
                    <div>Support</div>
                    <h3>{labels.support.name}</h3>
                </div>
                <p transition:fly="{trans2}">{labels.support.caption}</p>
            </article>

            <div class="wrapper" transition:fly="{trans3}">
                <MockupCard/>
            </div>
        {/if}
    </ContentWrapper>
</section>